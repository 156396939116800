import React, { createElement, useCallback } from 'react'
import loadable from '@loadable/component'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { Layout, Hero, SubMenu, DocAttribution } from '../components'
import { removeTrailingSlash } from '../utils'
import { featuredImgProps, heroProps } from '../proptypes'

const panelMap = {
  FullWidthPanel: loadable(() => import('../components/Panels/FullWidth')),
  CarouselPanel: loadable(() => import('../components/Panels/Carousel')),
  ImageDescriptionPanel: loadable(() =>
    import('../components/Panels/ImageDescription'),
  ),
  ImgTxtRowPanel: loadable(() => import('../components/Panels/ImgTxtRow')),
  DividerPanel: loadable(() => import('../components/Panels/Divider')),
  ColumnsPanel: loadable(() => import('../components/Panels/Columns')),
  BannerPanel: loadable(() => import('../components/Panels/Banner')),
}
const panelKeys = Object.keys(panelMap)

const PageTemplate = ({
  hero,
  featuredImage,
  slug,
  panels,
  inlineImages,
  showSubmenu,
  showAttribution,
  useBgPattern,
}) => {
  const [heroRef, heroTextInView] = useInView()
  const memoHeroRef = useCallback(() => heroRef, [heroRef])
  const pageClasses = [
    'content-page',
    `page-${
      slug === '/'
        ? 'home'
        : slug
            .split('/')
            .filter((i) => i)
            .join('-')
    }`,
    useBgPattern ? 'tooth-bg' : '',
    showSubmenu ? 'has-submenu' : '',
  ]
    .filter((i) => i)
    .join(' ')

  return (
    <div id="content" className={pageClasses}>
      <Hero {...hero} featuredImage={featuredImage} heroRef={memoHeroRef} />
      {!!panels && panels.length && (
        <section id="inner-content" className="cf">
          {!!showAttribution && (
            <div className="doc-attribution-holder wrap">
              <DocAttribution />
            </div>
          )}
          {panels
            .filter((panel) => panelKeys.includes(panel.type + 'Panel'))
            .map((props) =>
              createElement(panelMap[props.type + 'Panel'], {
                key: uuidv4(),
                inlineImages,
                ...props,
              }),
            )}
        </section>
      )}
      {!!showSubmenu && (
        <SubMenu
          items={panels.filter((p) => p.anchor && p.menuLabel)}
          heroTextInView={heroTextInView}
        />
      )}
    </div>
  )
}

PageTemplate.propTypes = {
  hero: heroProps,
  featuredImage: featuredImgProps,
  slug: PropTypes.string.isRequired,
  panels: PropTypes.array,
  inlineImages: PropTypes.array,
  showSubmenu: PropTypes.bool,
  showAttribution: PropTypes.bool,
  useBgPattern: PropTypes.bool,
}

const Page = ({ data }) => {
  const {
    hero,
    featuredImage,
    panels,
    showSubmenu,
    showAttribution,
    useBgPattern,
  } = data.markdownRemark.frontmatter
  const {
    inlineImages,
    slug,
    imageMobile,
    imageTablet,
    imageDesktop,
  } = data.markdownRemark.fields
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  if (featuredImage) {
    featuredImage.mobile = imageMobile
    featuredImage.tablet = imageTablet
    featuredImage.desktop = imageDesktop
  }
  const pageProps = {
    hero,
    featuredImage,
    slug,
    panels,
    showSubmenu,
    showAttribution,
    inlineImages,
    useBgPattern,
  }
  return (
    <Layout location={location} data={data}>
      <PageTemplate {...pageProps} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageTemplate($id: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
        imageMobile {
          ...mobileHeroImage
        }
        imageTablet {
          ...tabletHeroImage
        }
        imageDesktop {
          ...desktopHeroImage
        }
        inlineImages {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      frontmatter {
        ...seoFields
        ...heroFields
        ...featuredImageFields
        templateKey
        showSubmenu
        showAttribution
        useBgPattern
        panels {
          type
          align
          menuLabel
          anchor
          bgColor
          bgImg {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 1580, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          textColor
          title
          subtitle_MD
          content_MD
          slides {
            quote
            attribution
          }
          colImg {
            colWidth
            alignment
            src {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            classes
          }
          rowMeta {
            colWidth
            alignment
            alternate
          }
          rows {
            img {
              src {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
            icon {
              src {
                publicURL
              }
              alt
            }
            txt_MD
          }
          bannerImg {
            alt
            src {
              childImageSharp {
                fluid(
                  maxWidth: 860
                  maxHeight: 300
                  quality: 100
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          padding {
            top
            bottom
            left
            right
          }
          columns {
            align
            txtAbove
            img {
              src {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
            icon {
              src {
                publicURL
              }
              alt
            }
            iconImg
            txt_MD
          }
        }
      }
    }
  }
`
